.topPanel {
    width: 100%;
    border-bottom: 1px solid #E5E5E5;
    height: 43px;
}

.topPanelMenu {
    max-width: 1350px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 768px){
    .topPanel {
        width: 100% !important;
    }
    .topPanelMenu {
        margin-left: 10px;
    }
}