.nextDrawBox {
    background: #f8fcff;
    height: 238px;
}

.nextDrawContainer1 {
    display: flex;
    height: 238px;
    align-items: center;
    max-width: 1350px;
    margin: 0 auto;
}

.nextDrawContainer {
    display: flex;
    max-width: 1350px;
    align-items: center;
}

.nextDrawContainerInner {
    max-width: 350px;
    margin-right: 161px;
}

.nextDrawCaption {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: -0.84px;
    color: #333745;
}

.nextDrawDescription {
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.32px;
    color: #333745;
    mix-blend-mode: normal;
    opacity: 0.7;
}

.ballTimerContainer {
    display: flex;
    justify-content: space-between;
    width: 340px;
}

.baleTimerLabel {
    font-size: 16px;
    font-weight: 700;
    margin-top: 15px;
}

.ballTimer {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 50px;
    line-height: 60px;
    letter-spacing: 0.5px;
    color: #EEA51F;
    background: linear-gradient(0deg, #FCFCFC 0%, #FFFFFF 49%, #FCFCFC 51%, #FFFFFF 100%);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25098);
}

.lotteryJackPotContainer {
    max-width: 1350px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    margin-top: 105px;
}

.lotteryJackPotCaption {
    color: #333745;
    font-weight: 700;
    font-size: 52px;
    line-height: 62px;
}

.lotteryJackPotDescription {
    color: #333745;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    margin-top: 20px;
}

.lotteryJackPotDataContainer {
    display: flex;
    width: 970px;
    max-width: 970px;
    margin-top: 50px;
    justify-content: space-between;
}

.lotteryJackPotData {
    max-width: 350px;
    text-align: center;
    justify-content: center;

}

.lotteryJackPotDataAmount {
    font-size: 46px;
    color: #EA2E49;
    font-weight: 700;
    line-height: 34px;
    margin-top: 35px;
    margin-bottom: 14px;
}

.lotteryJackPotName {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 28px;
    letter-spacing: 0.4px;
}

.lotteryJackPotNextDraw {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    margin-bottom: 35px;
    letter-spacing: 0.3px;
    display: flex;
    text-align: center;
    justify-content: center;
}


.ballMega {
    background: url("../../images/ball_mega.png") no-repeat;
    background-size: contain;
    width: 104px;
    height: 104px;
    margin: 0 auto;
}

.ballLite {
    background: url("../../images/ball_lite.png") no-repeat;
    background-size: contain;
    width: 104px;
    height: 104px;
    margin: 0 auto;
}

.latestResults {
    background: url("../../images/bg_grey.png");
    background-size: contain;
    height: 965px;
    margin-top: 175px;
}

.latestResultsContainer {
    display: flex;
    margin: 0 auto;
    align-items: center;
    max-width: 1350px;
    height: 965px;
    text-align: center;
    justify-content: center;
}

.latestResultsCaption {
    font-size: 52px;
    line-height: 62px;
    font-weight: 700;
    margin-bottom: 18px;
}

.latestResultsDescription {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    margin-bottom: 47px;
}

.latestResultsTables {
    display: flex;
    width: 1110px;
    justify-content: space-between;
}

.latestResultsTable1 {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(51, 55, 69, 0.301961);
    border-radius: 10px;
    width: 730px;
    height: 483px;
}

.latestResultsTable2 {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(51, 55, 69, 0.301961);
    border-radius: 10px;
    width: 350px;
    height: 483px;
}

.latestResultsTableCaption {
    font-size: 28px;
    font-weight: 700;
    margin-top: 33px;
    margin-left: 25px;
    margin-bottom: 16px;
    text-align: left;
}

.latestResultsTable1Style {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    text-align: left;
}

.latestResultsTable1Style > thead > tr > th {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    background: #f1f2f4;
    border: 1px solid #E7E9ED;
    padding-top: 5px;
    padding-bottom: 6px;
    padding-left: 20px;
    box-sizing: border-box;
}

.latestResultsTable1Style > tbody > tr:nth-child(even) {
    background: #f1f2f4;
}

.latestResultsTable1Style > tbody > tr > td {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    border: 1px solid #E7E9ED;
    padding-top: 20px;
    padding-bottom: 19px;
    padding-left: 20px;
    box-sizing: border-box;
}

.ball {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 18px;
    border-radius: 50%;
    background: radial-gradient(85% 85% at 50% 50%, #026F27 0%, #001F0E 72.55%);
}

.latestResultsTable2Container {
    border-top: 2px solid #F1F2F4;
    margin-left: 30px;
    margin-right: 30px;
}

.latestResultsTable2Item {
    margin-top: 30px;
}

.latestResultsTable2ItemUser {
    display: flex;
    align-items: center;
}

.latestResultsTable2ItemFlag {
    font-size: 32px;
    height: 32px;
    margin-right: 20px;
}

.latestResultsTable2ItemUserName {
    font-size: 16px;
    color: #EEA51F;
    font-weight: 600;
}

.latestResultsTable2ItemGame {
    text-align: left;
    font-size: 16px;
    margin-top: 10px;
}

.latestResultsTable2ItemAmount {
    text-align: left;
    font-size: 20px;
    font-weight: 700;
    color: #02E32D;
    margin-top: 16px;
}

.whyChooseUs {
    padding-top: 110px;
    max-width: 1350px;
    margin: 0 auto;
    box-sizing: border-box;
}

.whyChooseUsCaption {
    font-size: 52px;
    font-weight: 700;
    line-height: 62px;
    text-align: center;
}

.whyChooseUsDescription {
    font-size: 16px;
    font-weight: 400;
    line-height: 42px;
    text-align: center;
}

.whyChooseUsBox {
    display: flex;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    margin-top: 65px;
}

.whyChooseUsCard {
    width: 204px;
    height: 211px;
    border: 1px solid rgba(64, 185, 247, 0.701961);
    box-sizing: border-box;
    border-radius: 10px;
    margin-right: 30px;
    margin-bottom: 30px;
    text-align: center;
}

.whyChooseUsCardExtra {
    background: linear-gradient(99.67deg, #33B5F7 6.04%, #1E91CB 93.96%);
    border: 1px solid rgba(51, 181, 247, 0.2);
    color: white;
    box-sizing: border-box;
    border-radius: 10px;
    width: 204px;
    height: 211px;
    margin-right: 30px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 14px;
    line-height: 23px;
    display: flex;
    padding: 18px;
}

.whyCard {
    width: 82px;
    height: 82px;
    margin: 38px auto 21px;
    box-sizing: border-box;
}

.whyCard1 {
    background: url("../../images/why/why_card1.png") no-repeat;
    background-size: contain;
}

.whyCard2 {
    background: url("../../images/why/why_card2.png") no-repeat;
    background-size: contain;
}

.whyCard3 {
    background: url("../../images/why/why_card3.png") no-repeat;
    background-size: contain;
}

.whyCard4 {
    background: url("../../images/why/why_card4.png") no-repeat;
    background-size: contain;
}

.whyCard5 {
    background: url("../../images/why/why_card5.png") no-repeat;
    background-size: contain;
}

.whyCardDescription {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
}

.whyBg {
    background: url("../../images/why/why_bg.jpeg") no-repeat;
    background-size: cover;
    width: 530px;
    height: 447px;
}

.howItWorks {
    width: 1350px;
    text-align: center;
    margin: 220px auto 0;
}

.howItWorksBg {
    background: url("../../images/how_it_works.png") no-repeat;
    background-size: contain;
    width: 900px;
    height: 500px;
    margin: 0 auto;
    margin-top: 94px;
}

.howItWorksCaption {
    font-size: 52px;
    font-weight: 700;
    line-height: 62px;
    text-align: center;
}

.howItWorksDescription {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;
}

.referralProgram {
    background: url("../../images/bg_grey1.png");
    background-size: cover;
    height: 953px;
}

.referralProgramContainer {
    display: flex;
    margin: 0 auto;
    align-items: center;
    max-width: 1350px;
    height: 965px;
    text-align: center;
    justify-content: center;
}

.referralProgramCaption {
    font-size: 52px;
    line-height: 62px;
    font-weight: 700;
    margin-bottom: 18px;
}

.referralProgramDescription {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    max-width: 729px;
    margin: 0 auto;
}

.referralProgramCardsContainer {
    width: 1110px;
    height: 341px;
    display: flex;
    margin-top: 47px;
    justify-content: space-between;
}

.referralProgramCard {
    width: 255px;
    height: 341px;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.101961);
    border-radius: 10px;
    box-sizing: border-box;
}

.refIcon {
    width: 100px;
    height: 100px;
    margin: 31px auto 35px;
    box-sizing: border-box;
}

.refIcon1 {
    background: url("../../images/referral/ref_icon1.png") no-repeat;
    background-size: contain;
}

.refIcon2 {
    background: url("../../images/referral/ref_icon2.png") no-repeat;
    background-size: contain;
}

.refIcon3 {
    background: url("../../images/referral/ref_icon3.png") no-repeat;
    background-size: contain;
}

.refIcon4 {
    background: url("../../images/referral/ref_icon4.png") no-repeat;
    background-size: contain;
}

.referralProgramCardCaption {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 20px;
}

.referralProgramCardDescription {
    font-size: 16px;
    line-height: 26px;
    max-width: 195px;
    text-align: center;
    margin: 0 auto;
}

.usersStat {
    background: #F7FCFF;
    height: 1006px;
}

.usersStatContainer {
    display: flex;
    margin: 0 auto;
    align-items: center;
    max-width: 1350px;
    height: 1006px;
    text-align: center;
    justify-content: center;
}

.usersStatCaption {
    font-size: 52px;
    line-height: 62px;
    font-weight: 700;
    margin-bottom: 18px;
}

.usersStatDescription {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    margin: 0 auto;
    margin-bottom: 47px;
    max-width: 730px;
}

.map {
    background: url("../../images/map.png") no-repeat;
    background-size: contain;
    width: 777px;
    height: 357px;
    margin: 0 auto;
}

.usersStatDataContainer {
    display: flex;
    justify-content: center;
}

.usersStatData {
    width: 300px;
    min-height: 103px;
}

.usersStatDataAmount {
    color: #EEA51F;
    font-size: 42px;
    line-height: 62px;
    margin-bottom: 11px;
}

.usersStatDataText {
    font-size: 16px;
    line-height: 30px;
    color: #909090;
}

.team {
    background: #F7FCFF;
    height: 773px;
}

.teamContainer {
    display: flex;
    margin: 0 auto;
    align-items: center;
    max-width: 1087px;
    height: 1006px;
    text-align: center;
    justify-content: center;
}

.teamCaption {
    font-size: 52px;
    line-height: 62px;
    font-weight: 700;
    margin-bottom: 18px;
}

.teamDescription {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    margin: 0 auto;
    margin-bottom: 47px;
    max-width: 730px;
}

.teamPersonsContainer {
    display: flex;
    justify-content: space-between;
    width: 1087px;
    margin-top: 48px;
}

.teamPerson {
    width: 231px;
}

.avatar {
    width: 231px;
    height: 231px;
    border-radius: 50%;
}

.avatarAri {
    background: url("../../images/persons/ari.png");
    background-size: contain;
}

.avatarJyrky {
    background: url("../../images/persons/jyrki.png");
    background-size: contain;
}

.avatarJanne {
    background: url("../../images/persons/janne.png");
    background-size: contain;
}

.avatarJaneric {
    background: url("../../images/persons/janeric.png");
    background-size: contain;
}

.teamPersonName {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    margin-top: 24px;
}

.teamPersonRole {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin-top: 12px;
    color: #666666;
    font-style: italic;
}

.footer {
    height: 400px;
    background: url("../../images/footer.png") no-repeat;
    background-size: contain;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerMenu {
    display: flex;
}

.footerMenuColumn {
    margin-right: 30px;
}

.footerMenuTitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 15px;
}

.footerMenuItem {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 30px;
    margin-left: 10px;
    cursor: pointer;
}

.modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    outline: none;
    width: 500px;
    height: 500px;
    background-color: white;
    box-sizing: border-box;
}


@media screen and (max-width: 768px) {
    .howItWorks {
        width: 100% !important;
        margin-top: 30px;
    }

    .howItWorksBg {
        max-width: 100%;
    }

    .lotteryJackPotContainer {
        width: 100% !important;
    }

    .nextDrawContainer {
        width: 100%;
        display: block;
    }

    .lotteryJackPotDataContainer {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
    }

    .latestResultsContainer {
        max-width: 100%;
        display: block;
        padding: 10px;
    }

    .latestResultsTables {
        max-width: 100%;
        display: block;
    }

    .latestResultsTable1, .latestResultsTable2 {
        width: 100%;
        height: 100%;
    }

    .whyChooseUs {
        max-width: 100%;
        padding-top: unset;
    }

    .whyChooseUsBox {
        max-width: 100%;
    }

    .referralProgram {
        max-width: 100%;
    }

    .referralProgramInner {
        max-width: 100%;
    }

    .referralProgramContainer {
        max-width: 100%;
        height: auto;
    }

    .referralProgramCardsContainer {
        max-width: 100%;
        overflow: scroll;
    }

    .nextDrawBox {
        height: auto;
    }

    .nextDrawContainer1 {
        height: auto;
    }

    .nextDrawContainerInner {
        max-width: 100%;
        margin: 0 !important;
    }

    .nextDrawContainer1 {
        padding: 20px 10px;
        box-sizing: border-box;
    }

    .nextDrawCaption {
        text-align: center;
    }

    .nextDrawDescription {
        margin-top: 10px;
        text-align: center;
    }

    .ballTimerContainer {
        margin-top: 30px;
    }

    .lotteryJackPotDataAmount {
        font-size: 28px;
    }

    .lotteryJackPotCaption {
        font-size: 40px;
        line-height: 46px;
    }

    .latestResultsTable1Style > thead > tr > th {
        padding-left: 12px;
    }

    .latestResultsTable1Style > tbody > tr > td {
        padding-left: unset;
        text-align: center;
    }
    .ball {
        margin: 0 auto;
    }
    .latestResultsTableCaption {
        padding-top: 10px;
    }
    .latestResults {
        margin-top: 70px;
        height: 1385px;
    }
    .latestResultsCaption, .whyChooseUsCaption {
        font-size: 40px;
        line-height: 46px;
        padding-top: 30px;
    }

    .whyChooseUsDescription {
        line-height: 20px;
    }

    .howItWorksBg {
        height: 280px;
    }

    .referralProgramCaption {
        font-size: 40px;
        line-height: 46px;
        margin-top: 30px;
    }

    .referralProgramCard {
        width: 210px;
        min-width: 210px;
        margin-left: 10px;
    }

    .whyChooseUsBox {
        padding-left: 10px;
    }
    .whyChooseUsCard {
        max-width: 110px;
        margin-right: 10px;
    }
    .whyChooseUsCardExtra {
        max-width: 110px;
        padding: unset;
        line-height: 19px;
    }
    .whyCard {
        margin: 10px auto 5px;
    }
    .lotteryJackPotNextDraw {
        display: block;
    }
    .map {
        width: 400px;
        height: 250px;
    }
    .usersStatContainer {
        max-width: 100%;
    }
    .usersStatDataContainer {
        display: block;
    }
    .usersStatData {
        margin: 0 auto;
    }

    .usersStatDataAmount {
        margin-top: 10px;
        margin-bottom: 0;
    }

    .teamPersonsContainer {
        width: 100%;
        display: block;
    }

    .team, .teamContainer {
        height: 1870px;
    }

    .teamPerson {
        margin: 0 auto;
        margin-bottom: 40px;
    }
    .footer {
        max-width: 100%;
    }
    .footerMenu {
        display: block;
    }
    .howItWorksDescription, .whyChooseUsDescription {
        max-width: 100%;
    }
}


