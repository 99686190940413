.guestBody {
    background-color: white;
    height: 100%;
    background-size: cover;
}

@media screen and (max-width: 768px){
    .guestBody {
        width: 100% !important;
    }
}