* {
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
}

#root {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Ubuntu", sans-serif;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;
    color: #333745;
    height: 100%;
}

.center {
    text-align: center;
    justify-content: center;
}

@font-face {
    font-family: 'Ubuntu';
    src: local('Ubuntu'), url('./fonts/Ubuntu/Ubuntu-Regular.ttf') format('opentype');
}

@media screen and (max-width: 768px){
    .centeredButton {
        margin: 0 auto;
        display: flex;
        justify-content: center;
    }
}



