.header {
    background: url("../../images/bg.png");
    background-position-x: right;
    background-repeat: no-repeat;
    max-width: 1350px;
    height: 705px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin: 0 auto;
}

.headerInner {
    max-width: 561px;
}

.headerCaption {
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 66px;
    letter-spacing: -1.8px;
    color: #333745;
}

.headerDescription {
    margin-top: 20px;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.32px;
    color: #333745;
    opacity: 0.8;
    mix-blend-mode: normal;
}


@media screen and (max-width: 768px){
    .header {
        width: 100% !important;
        background: white;
        background: url("../../images/bg.png");
        background-position-x: center;
        background-position-y: top;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .headerInner {
        margin-top: 250px;
    }
    .headerCaption {
        text-align: center;
        font-size: 40px;
        line-height: 46px;
    }
    .headerDescription {
        text-align: center;
    }
}